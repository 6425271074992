<template>
  <div class="app">
    <CRow>
      <CCol sm="4">
        <h4 class="text-uppercase">Tasks</h4>
      </CCol>

      <CCol sm="8"> </CCol>
    </CRow>

    <CRow>
      <CCol sm="12">
        <CCard>
          <CRow class="pb-3">
            <CCol sm="12">
              <form v-on:submit.prevent="filterTask(filter)">
                <CRow>
                  <CCol sm="2" class="pr-0">
                    <CInput
                      v-model="filter.uid"
                      placeholder="Task ID"
                    />
                  </CCol>
                  <CCol sm="2" class="pr-0">
                    <select class="form-control" v-model="filter.status">
                      <option selected value="">Select Status</option>
                      <option value="incomplete">PENDING</option>
                      <option value="COMPLETED">COMPLETED</option>
                    </select>
                  </CCol>
                  <CCol sm="3" class="pr-0">
                    <CInput
                      v-model="filter.account_number"
                      placeholder="Consumer Account Number"
                    />
                  </CCol>
                  <CCol sm="3" class="pr-0">
                    <CInput
                      v-model="filter.mobile_number"
                      placeholder="Mobile No"
                    />
                  </CCol>

                  <CCol sm="2">
                    <button class="btn btn-success w-100">Search</button>
                  </CCol>
                </CRow>
              </form>
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="12">
              <div class="table-responsive">
                <table class="table table-borderless">
                  <thead>
                    <tr class="text-center">
                      <th scope="col">SL</th>
                      <TableColumn align="center" name="Task ID" sort="uid" :position="filter.sortOrder" :id="filter.sortBy" @sort="changeSort" />
                      <TableColumn align="center" name="Title" sort="name" :position="filter.sortOrder" :id="filter.sortBy" @sort="changeSort" />
                      <TableColumn align="center" name="Type" sort="type" :position="filter.sortOrder" :id="filter.sortBy" @sort="changeSort" />
                      <TableColumn align="center" name="Resolution Time (hrs)" sort="resolution_time" :position="filter.sortOrder" :id="filter.sortBy" @sort="changeSort" />
                      <TableColumn align="center" name="Consumer Name" sort="customer_account_id" :position="filter.sortOrder" :id="filter.sortBy" @sort="changeSort" />
                      <TableColumn align="center" name="Account No" sort="customer_id" :position="filter.sortOrder" :id="filter.sortBy" @sort="changeSort" />
                      <th scope="col">Mobile No</th>
                      <TableColumn align="center" name="Status" sort="status" :position="filter.sortOrder" :id="filter.sortBy" @sort="changeSort" />
                      <!-- <th scope="col">Description</th> -->
                      <TableColumn align="center" name="Completed At" sort="completed_at" :position="filter.sortOrder" :id="filter.sortBy" @sort="changeSort" />
                      <th scope="col">Completed Duration</th>
                      <th scope="col" v-if="checkUsrPermissions(['Task_all', 'Task_show'])">Actions</th>
                    </tr>
                  </thead>
                  <tbody v-if="tasks.length">
                    <tr
                      class="text-center"
                      v-for="(task, index) in tasks"
                      :key="task.id"
                    >
                      <td scope="row">{{ meta.from + index }}</td>
                      <td>{{ task.uid }}</td>
                      <td>
                        {{ task.name }}
                      </td>
                      <td>{{ task.type }}</td>
                      <td>{{ task.resolution_time }}</td>
                      <td>{{ task.customer_name }}</td>
                      <td>{{ task.customer_acc_no }}</td>
                      <td>{{task.mobile_number}}</td>
                      <td>
                        <span
                          class="badge p-2"
                          :class="
                            task.status === 'PENDING'
                              ? 'badge-warning'
                              : task.status === 'METER_INSTALLED'
                              ? 'badge-primary'
                              : 'badge-success'
                          "
                          >{{ task.status }}</span
                        >
                      </td>
                      <!-- <td>{{ task.description }}</td> -->
                      <td>
                        <span v-if="task.completed_at">
                          {{ task.completed_at | dateTimeFormat }}</span
                        >
                      </td>
                      <td>{{task.total_duration}}</td>
                      <td v-if="checkUsrPermissions(['Task_all', 'Task_show'])">
                        <span
                          v-if="checkUsrPermissions(['Task_all', 'Task_show'])"
                          class="mb-1 pointer"
                          @click="$router.push('/tasks/' + task.id)"
                          shape="pill"
                        >
                          <i class="fa fa-eye fa-2x color-view"></i>
                        </span>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="10">
                        <h5 class="text-center">Data Not Available.</h5>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </CCol>
          </CRow>
          <CPagination
            v-if="tasks.length"
            align="center"
            :pages="rows"
            :active-page.sync="filter.currentPage"
            @update:activePage="handlePageChange"
          />
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { freeSet } from "@coreui/icons";
import TableColumn from "@/components/TableColumn.vue";

export default {
  name: "Tasks",
  icons: { freeSet },
  data: () => {
    return {
      filter: {
        mobile_number: "",
        hardware_serial_no: "",
        account_number: "",
        currentPage: 1,
        status: "",
        sortBy: "id",
        sortOrder: "desc",
      },
      deletingState: false,
      loadingState: false,
      role: {},
      serverErrors: [],
      showDismissibleAlert: false,
    };
  },
  components: {TableColumn},
  filters: {
    moment: function (date) {
      return moment(date).format("DD MMM, YYYY HH:mm:ss");
    },
  },
  methods: {
    changeSort(event) {
      this.filter.sortBy = event[0];
      this.filter.sortOrder = event[1];
      this.$store.dispatch("Task/getTasks", this.filter);
    },
    handleClick() {
      this.$router.push("/my/cool/link");
    },
    viewMeter(meter) {
      return meter;
    },

    filterTask(search) {
      if (search) {
        search.currentPage = 1;
        this.$router.replace({name: "Tasks", query: this.filter});
        this.$store.dispatch("Task/getTasks", search);
      }
    },

    dateFormat(date) {
      return moment(date).format("Do MMM, YYYY h:mm a");
    },
    handlePageChange(value) {
      this.filter.currentPage = value;
      this.$router.replace({name: "Tasks", query: this.filter});
      this.$store.dispatch("Task/getTasks", this.filter);
    },
    setSearchParams() {
      this.filter.account_number = this.$route.query.account_number?this.$route.query.account_number:'';
      this.filter.mobile_number = this.$route.query.mobile_number?this.$route.query.mobile_number:'';
      this.filter.hardware_serial_no = this.$route.query.hardware_serial_no?this.$route.query.hardware_serial_no:'';
      this.filter.currentPage = this.$route.query.currentPage?parseInt(this.$route.query.currentPage):1;
      this.filter.status = this.$route.query.status?this.$route.query.status:'';
    }
  },
  computed: {
    ...mapGetters("Task", ["tasks", "rows", "perPage", "meta"]),
  },
  mounted() {
    this.setSearchParams();
    this.$store.dispatch("Task/getTasks", this.filter);
  },
};
</script>

<style scoped>
.custom-button {
  color: #000;
  padding: 10px 8px 10px 15px;
  font-size: 15px;
  border-bottom: 1px solid rgba(204, 204, 204, 0.4);
  line-height: 1;
}

.card {
  border: none;
  padding: 15px 15px 0;
}
tbody:before {
  content: "@";
  display: block;
  line-height: 30px;
  text-indent: -99999px;
}
</style>

<style lang="scss" scoped>
div {
  h4 {
    font-size: 15px;
    font-weight: bold !important;
    color: #000;
  }
  .table {
    font-size: 14px;

    thead {
      tr {
        th {
          background: #f8f9fa;
          color: #000;
        }
      }
    }
    tbody {
      font-size: 13px;
      tr {
        color: #000;
        td {
          padding: 0.75rem 0 0.75rem 0.75rem;
          button {
            font-size: 13px;
          }
          .pointer {
            cursor: pointer;
          }
        }
        td:nth-child(2) {
          color: #5e76e7;
        }
        td:nth-child(2) {
          font-weight: 500 !important;
        }
      }
    }
  }
}
</style>
